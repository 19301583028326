const DEV = process.env.NODE_ENV === 'development';
import { loadProject } from '../scripts/loadProject';

//import imagesLoaded from 'imagesloaded';

export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('init common');

		// Remove no-js
		document.documentElement.classList.remove('no-js');
		document.documentElement.classList.add('js');

		// Test touch
		if (!('ontouchstart' in document.documentElement)) {
			document.documentElement.classList.add('no-touch');
		} else {
			document.documentElement.classList.add('touch');
		}

		function hideLoader() {
			document.body.classList.add('loaded');
		}

		jQuery(document).ready(function ($) {
			setTimeout(() => {
				hideLoader();
			}, 1000);
		});

		loadProject();

		//Toggle class
		const toggleClassBtns = document.querySelectorAll('.js-toggle-class');
		if (toggleClassBtns) {
			toggleClassBtns.forEach((item, i) => {
				item.addEventListener('click', function (e) {
					this.classList.toggle('active');
					var tgId = this.dataset.target;
					var panel = document.getElementById(tgId);
					var classname = this.dataset.classname;
					if (panel && classname) {
						panel.classList.toggle(classname);
					}
				});
			});
		}
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		console.log('finalize common');
	},
};
