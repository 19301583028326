import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

export default {
	init() {
		console.log('home');

		CustomEase.create('custom', '0.16, 1, 0.3, 1');

		if (document.getElementById('intro')) {
			var introTl;

			gsap.set('.intro_text', { yPercent: 100, opacity: 1 });
			gsap.set('.intro_logo', { yPercent: 110, opacity: 1 });
			gsap.set('.intro_visual', { yPercent: -100, opacity: 1 });
			gsap.set('.intro_visual_delay', { yPercent: -400, opacity: 1 });

			if (window.innerWidth >= 769) {
				gsap.set('.homepage_content', { yPercent: 100, opacity: 1 });
				introTl = gsap.timeline({
					onComplete: showPage,
				});
				playIntro();
			} else {
				gsap.set(['#hpContent', '.open_menu '], { opacity: 0 });
				introTl = gsap.timeline({
					onComplete: showPageMobile,
				});
				playIntroMobile();
			}

			function playIntro() {
				introTl
					.to('.intro_text', {
						duration: 1.5,
						delay: 1,
						yPercent: 0,
						stagger: 0.07,
						ease: 'custom',
					})
					.add('logo', 2)
					.to(
						'.intro_logo',
						{
							duration: 3.5,
							yPercent: 0,
							stagger: 0.1,
							ease: 'custom',
						},
						'logo'
					)
					.to(
						'.intro_visual',
						{
							duration: 3.5,
							yPercent: 0,
							ease: 'custom',
						},
						'logo'
					)
					.to(
						'.intro_visual_delay',
						{
							duration: 3.5,
							yPercent: 0,
							ease: 'custom',
						},
						'logo'
					);
			}

			function showPage() {
				var showTl = gsap.timeline();
				showTl
					.add('start')
					.to(
						'.homepage_content',
						{
							duration: 1,
							yPercent: 0,
							ease: 'custom',
						},
						'start'
					)
					.to(
						'.intro',
						{
							duration: 1,
							y: () =>
								`-${document.getElementById('logo').offsetTop}`,
							ease: 'custom',
							onComplete: function () {
								document.getElementById(
									'wrapper'
								).style.height = 'auto';
								document.getElementById(
									'wrapper'
								).style.overflow = 'visible';
								document.getElementById('intro').remove();
								document.getElementById('introMobile').remove();
								document.getElementById(
									'headerLogo'
								).style.display = 'block';

								gsap.timeline({
									scrollTrigger: {
										trigger: '#hpContent',
										start: 'top top',
										end: 'bottom top',
										endTrigger: 'html',
										//markers: true,
										onToggle: (self) => {
											if (self.isActive) {
												var headerTl = gsap.timeline();
												headerTl
													.add('start')
													.to(
														'#menu-main-menu',
														{
															duration: 1,
															opacity: 1,
														},
														'start+=0.5'
													)
													.to(
														'#hpContent>div',
														{
															duration: 0.8,
															marginTop: '128px',
															ease: 'custom',
														},
														'start'
													);
												document
													.querySelector(
														'.main_header'
													)
													.classList.add('active');
											}
										},
									},
								});
							},
						},
						'start'
					);
			}

			function playIntroMobile() {
				introTl
					.add('logo')
					.to(
						'.intro_text',
						{
							duration: 3,
							delay: 1,
							yPercent: 0,
							stagger: 0.07,
							ease: 'custom',
						},
						'logo'
					)
					.to(
						['.intro_visual', '.intro_visual_delay'],
						{
							duration: 3,
							delay: 1,
							yPercent: 0,
							ease: 'custom',
						},
						'logo'
					);
			}

			function showPageMobile() {
				gsap.to('#introMobile', {
					autoAlpha: 0,
					duration: 1,
					onComplete: function () {
						gsap.to(['#hpContent', '.open_menu '], {
							duration: 1,
							delay: 0.5,
							opacity: 1,
							onComplete: function () {
								document.getElementById(
									'wrapper'
								).style.height = 'auto';
								document.getElementById(
									'wrapper'
								).style.overflow = 'visible';
								document.getElementById('intro').remove();
								setDesktopEndState();
							},
						});
					},
				});
			}

			function setDesktopEndState() {
				document.getElementById('headerLogo').style.display = 'block';
				gsap.set('#menu-main-menu', {
					opacity: 1,
				});
				document.querySelector('.main_header').classList.add('active');
				document.getElementById('introMobile').remove();
				document.body.classList.remove('intro--on');
			}
		}
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
