import { setProject } from '../scripts/setSingleProject';

export default {
	init() {
		setProject();
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
