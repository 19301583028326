import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const setProject = () => {
	CustomEase.create('custom', '0.7, 0, 0.31, 0.99');
	gsap.set('.project_overlay_inner .js-next-project', {
		yPercent: 110,
	});

	jQuery(document).ready(function ($) {
		console.log('single project');

		gsap.to('.js-next-project', {
			scrollTrigger: {
				scroller: '.project_overlay_inner',
				trigger: '#triggerNext',
				start: 'top bottom',
				//markers: true,
			},
			delay: 2,
			duration: 1.5,
			ease: 'custom',
			yPercent: 0,
		});

		setTimeout(() => {
			ScrollTrigger.refresh();
		}, 500);

		$('.js-toggle-tab').bind('click', function (e) {
			e.preventDefault();
			var tg = $(this).attr('data-target');
			if (tg) {
				$('.panel').hide();
				$(tg).show();
				$('.js-toggle-tab').removeClass('active');
				$(this).addClass('active');
				resetLevels();
			}
		});

		$('.js-toggle-level').bind('click', function (e) {
			e.preventDefault();
			var tg = $(this).attr('data-target');
			if (tg && tg != 'all') {
				var $siblingsBefore = $(tg).prevAll();
				var $siblingsAfter = $(tg).nextAll();
				gsap.to(tg, {
					duration: 1,
					ease: 'custom',
					yPercent: 0,
					opacity: 1,
				});
				$siblingsBefore.each(function (index, item) {
					gsap.to(item, {
						duration: 1,
						ease: 'custom',
						yPercent: -80,
						opacity: 0.3,
					});
				});
				$siblingsAfter.each(function (index, item) {
					gsap.to(item, {
						duration: 1,
						ease: 'custom',
						yPercent: 80,
						opacity: 0.3,
					});
				});
				$('.js-toggle-level').removeClass('active');
				$(this).addClass('active');
			} else {
				resetLevels();
			}
		});

		function resetLevels() {
			$('.js-toggle-level').removeClass('active');
			$('.js-toggle-level[data-target="all"]').addClass('active');
			gsap.to('.level_panel', {
				duration: 1,
				ease: 'custom',
				yPercent: 0,
				opacity: 1,
			});
		}
	});
};

export { setProject };
