import gsap from 'gsap';
import { Flip } from 'gsap/Flip';
gsap.registerPlugin(Flip);
import { setProject } from './setSingleProject';

const loadProject = () => {
	jQuery(document).ready(function ($) {
		console.log('project');

		let $projectOverlay = $('.project_overlay'),
			$projectContainer = $('.project_overlay_content'),
			$projectContainerFade = $('.project_overlay_fade'),
			$projNum = $('#projNum'),
			$projTitle = $('#projTitle'),
			$body = $('body'),
			projectOverlayOpen = false;

		$('.js-open-project').bind('click', function (e) {
			e.preventDefault();
			var $this = $(this);
			var url = $this.attr('href'),
				num = $this.find('.proj_num').text(),
				title = $this.find('.proj_title').text();
			if (url) {
				$projNum.html(num);
				$projTitle.html(title);
				openOverlay(url);
			}
		});
		$('.js-close-project').bind('click', function (e) {
			e.preventDefault();
			closeProject();
		});

		$('.project_overlay').delegate(
			'.js-next-project',
			'click',
			function (e) {
				e.preventDefault();
				const $this = $(this);
				const nextProjPanel = document.querySelector('.next_proj_btn');
				const state = Flip.getState(nextProjPanel);
				var isOpen = nextProjPanel.classList.add('fullscreen');
				/* if (isOpen) {
					document.body.classList.add('overflow-hidden');
				} */
				Flip.from(state, {
					duration: 1.5,
					ease: 'custom',
					absolute: true,
					absoluteOnLeave: true,
					onComplete() {
						if (!isOpen) {
							document.body.classList.remove('overflow-hidden');
						}
						var url = $this.attr('href'),
							num = $this.find('.proj_num').text(),
							title = $this.find('.proj_title').text();
						if (url) {
							$projNum.html(num);
							$projTitle.html(title);
							$projectContainer.empty();
							openProject(url);
						}
					},
				});
			}
		);

		function openOverlay(url) {
			projectOverlayOpen = true;
			$body.addClass('overflow-hidden');
			$projectOverlay.addClass('open');
			openProject(url);
		}

		function openProject(url) {
			$projectContainer.empty();
			$projectContainerFade.removeClass('loaded');
			$projectContainer.load(url + ' #content', function () {
				$projectContainerFade.addClass('loaded');
				setProject();
			});
		}

		function closeProject() {
			$body.removeClass('overflow-hidden');
			if ($projectOverlay) {
				$projectOverlay.removeClass('open');
				projectOverlayOpen = false;
			}
		}
	});
};

export { loadProject };
